/* Cookie */
// const session = localStorage.getItem('banhjipossession') != null ? JSON.parse(localStorage.getItem('banhjipossession')) : {}
// const inst = session.ins || {}
const instituteId = localStorage.getItem('instituteId') != null ? localStorage.getItem('instituteId') : ''

const myUrl = require("@/url")
const baseUrl = myUrl.url
const url = baseUrl + '/warehouse-service/'

module.exports = {
    warehouseCreate                                : url + 'warehouses/' + instituteId + '/create',
    warehouseGet                                   : url + 'warehouses/' + instituteId + '/get',
    warehouseGets                                  : url + 'warehouses/' + instituteId + '/list'
}