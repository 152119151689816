module.exports = {
    country: 'https://raw.githubusercontent.com/samayo/country-json/master/src/country-by-abbreviation.json',
    customer_type_nature_type: 'https://gist.githubusercontent.com/choeun88/feab25a1de93238add6e7d83f5525236/raw/88846814f4ecd666b8b8e26bea5396f2dfde61ef/customer_type_nature.json',
    customer_group_price_level: 'https://gist.githubusercontent.com/choeun88/a738c4dd84fbc9e17e1b70c028a715a0/raw/6ed73fee2f8d3883f4ba4e268aa9fa293ca95b9c/customer_group_price_level.json',
    customer_main_type: 'https://gist.githubusercontent.com/choeun88/17ca423db7734155cd1c60eb0935d250/raw/103c40038509f3869dba72d3b0b194b01b3263fa/cutomer_type.json',
    customer_bill_to: 'https://gist.githubusercontent.com/choeun88/c5e81f15c86e1523d916347d77002e2c/raw/c01f6529a04b79a46a19a59e7883702b2b348364/customer_bill_to.json',
    gender: [{name: 'Male'}, {name: 'Female'}, {name: 'Others'}],
    maritalStatus: [
        {id: '0786df44-3b85-11eb-adc1-0242ac120002', name: 'Single'},
        {id: '0786e462-3b85-11eb-adc1-0242ac120002', name: 'Engaged'},
        {id: '0786e57a-3b85-11eb-adc1-0242ac120002', name: 'Married'},
        {id: '0786e656-3b85-11eb-adc1-0242ac120002', name: 'divorced'},
        {id: '0786e728-3b85-11eb-adc1-0242ac120002', name: 'Widowed'}
    ],
    paymentPeriod: [
        {
            id: '98c3ce4a-3b85-11eb-adc1-0242ac120002',
            name: 'Hourly'
        },
        {
            id: '98c3d2be-3b85-11eb-adc1-0242ac120002',
            name: 'Daily'
        },
        {
            id: '98c3d3cc-3b85-11eb-adc1-0242ac120002',
            name: 'Weekly'
        },
        {
            id: '98c3d49e-3b85-11eb-adc1-0242ac120002',
            name: 'monthly'
        }
    ],
    nationality: 'https://gist.githubusercontent.com/Darith-Mokpost/87e77be63691b0019125f16d2d9cfb74/raw/b9234a4360a573a44eed36c24466932cd50eec3e/nationality_khmer.json',
    bank: [
        {
           "address":"# 263, Preah Ang Duong St. Sangkat Wat Phnom, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"RHB Indochina Bank Limited",
           "number":"134",
           "phone":"",
           "email":"",
           "swift_code":"OSKIKHPP",
           "abbr":"RHB",
           "uuid":"39ab0c47-1553-480c-a6ce-4ba700ea87ca",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No 462, Street 93, Sangkat Tonle Basak, Khan Chamkar Mon, Phnom Penh",
           "name":"B.I.C (Cambodia) Bank Plc.",
           "number":"104",
           "phone":"",
           "email":"",
           "swift_code":"BIOBKHPP",
           "abbr":"BIC",
           "uuid":"3bb7364b-6d04-403a-a32f-6676f0e2065f",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"",
           "name":"Samporn Samakum Sahakreas Thuntoch Neung Matjum Kampuchea Plc",
           "number":"357",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"d2b6e0dd-05bb-436e-a2c4-b55ed4011b1f",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"At Canadia Tower 1st- 2nd Floor, No 315, Preah Monivong Blvd, Coner Of Street Ang Duong, Sangkat Wat Phnom, Khan Daun Penh,Phnom Penh, Cambodia.",
           "name":"Bank of China (Hong Kong) Limited Phnom Penh Branch",
           "number":"107",
           "phone":"",
           "email":"",
           "swift_code":"BKCHKHPP",
           "abbr":"BankOfChina",
           "uuid":"219a4930-3d0f-4cc7-815f-574c3b35d678",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 1E, Maeda (St. 2004), Sangkat Kakab, 12406 Phnom Penh",
           "name":"KEY Microfinance Institution Plc",
           "number":"330",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"eb982452-8f2f-45ee-8f46-a2b6cb543c87",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#23, Kramuon Sar Avenue (St.114), Sangkat Phsar Thmey 2, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Cambodian Public Bank Plc.",
           "number":"117",
           "phone":"",
           "email":"",
           "swift_code":"CPBLKHPP",
           "abbr":"",
           "uuid":"d217cc5c-4b29-4db2-aa66-dd68a25c66d2",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#93, Preah Norodom Blvd. corner of St. 208, Sangkat Boeung Raing, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"MIA Financial Plc.",
           "number":"337",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"6375e735-63f5-478e-a8e9-8f7a801b8dd1",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No 100, Preah Sihanouk Boulevard, Sangkat Boeung Keng Kang I, Khan Chamkamon, Phnom Penh, Cambodia.",
           "name":"JTRUST ROYAL BANK LTD.",
           "number":"103",
           "phone":"",
           "email":"",
           "swift_code":"TCABKHPP",
           "abbr":"JTrust",
           "uuid":"dc7a9fe8-f761-4345-b89e-4d9589e4e30e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 189-191, Kampuchea Krom (St. 128), 12252, Phnom Penh, Cambodia",
           "name":"Entean Akpevath Pracheachun Limited",
           "number":"320",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"43cfabef-928d-4154-b97d-2c5c3b145623",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"285 Yothapol Khemarak Phoumin Blvd (271), Phnom Penh",
           "name":"AMK Microfinance Institution Plc.",
           "number":"501",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"AMK",
           "uuid":"54148d0f-3c57-4c2b-9dbe-7f6ecfde466f",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#202, Preah Norodom Blvd, Sangkat Tonle Bassac, Khan Chankarmon, Phnom Penh, Cambodia.",
           "name":"Angkor Capital Specialized Bank",
           "number":"203",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"00f8bd4f-27c8-4678-97fd-43bc86db7ced",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#60, Preah Noromdom Blvd, Phnom Penh, Cambodia.",
           "name":"Sacombank Cambodia Plc",
           "number":"135",
           "phone":"",
           "email":"",
           "swift_code":"SGTTVNVX",
           "abbr":"Sacombank",
           "uuid":"597b2b3e-3fd1-4708-a6a9-7dbf7dc0332d",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"៩៦Eo, កម្ពុជាក្រោម (១២៩), ផ្សេងៗ, Monourom, Prampi Makara, Phnom Penh, Cambodia",
           "name":"Bai Sen Technology Plc",
           "number":"420",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"6b6938d2-d9f6-4479-ab03-b7859f2ce214",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Building 159-161, St. 113, BKK III Boeung Keng Kang, PP. 12304",
           "name":"Speed Pay Plc",
           "number":"406",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"SpeedPay",
           "uuid":"bd246a22-15f6-421c-bc32-396b414f626b",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#28, Samdech Pan Avenue (St.214), Sangkat Boeung Raing, Khan Daun Penh, Phnom Penh",
           "name":"Hong Leong Bank (Cambodia) Plc.",
           "number":"124",
           "phone":"",
           "email":"",
           "swift_code":"HLBBKHPP",
           "abbr":"",
           "uuid":"5fdb5b5d-741f-4f4e-afc3-9231e13c820e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#344 (1st, 2nd floor), Mao Tse Toung Blvd., Sangkat Tuol Svay Prey 1, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Bangkok Bank Public Company Limited, Cambodia Branch",
           "number":"105",
           "phone":"",
           "email":"",
           "swift_code":"BKKBKHPP",
           "abbr":"",
           "uuid":"c425a527-f7b1-4b46-81c3-b71b59a2a6a0",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"27 th floor of Canadia Tower, Phnom Penh.",
           "name":"Canadia-Fullerton Mobile Money Plc",
           "number":"407",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"aa099633-0cbe-4186-b58f-ded497d96351",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 153B, St. 598, 12101 Phnom Penh",
           "name":"Taca Microfinance Plc",
           "number":"363",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"9b18b303-b930-49e7-8fd9-d72236604fc8",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 83AE0+83BE0, St1986, Phnom Penh Thmei Sangkat, Sen Sok Khan, Phnom Penh Capital, Cambodia",
           "name":"Samaky Microfinance Plc",
           "number":"354",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"48abf923-413b-41fb-a2b5-a67aa04c3b84",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#85&87-88, St. Kim Il Sung (289), Sangkat Boeung kak II, Phnom Penh, Cambodia.",
           "name":"LY HOUR Microfinance Institution Plc",
           "number":"335",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"9ab04b6b-f571-4287-a595-385d5053b19f",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"",
           "name":"GROW Plc",
           "number":"326",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"e1afcf8b-1a78-4628-ba5c-1daa282a430a",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 265-269, Ang Duong Street., Sangkat Wat Phnom, Khan Daun Penh, Phnom Penh.Cambodia.",
           "name":"Cambodia Post Bank Plc.",
           "number":"115",
           "phone":"",
           "email":"",
           "swift_code":"CPBPKHP2",
           "abbr":"",
           "uuid":"7fe47e04-0301-4874-a7be-f6d99858cc13",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#43, Preah Norodom Blvd., Sangkat Phsar Thmey 3, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Maybank (Cambodia) Plc.",
           "number":"128",
           "phone":"",
           "email":"",
           "swift_code":"MBBEKHPP",
           "abbr":"MayBank",
           "uuid":"bd9b2e94-6e22-4ae9-8cce-ade650b29c23",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#132 St. 193, Tuol Svay Prey Ti Muoy Sangkat, Chamkar Mon Khan, Phnom Penh Capital, Cambodia",
           "name":"PRASETHPHEAP Finance Plc",
           "number":"346",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"37979341-d334-4313-b384-ccb1cf253dfd",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#A-15, St. 271, Sangkat Tomnub Teuk Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"First Finance Plc",
           "number":"322",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"072f6f6a-9813-4237-88d9-1bbfe6119c79",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"៩៨៤ (ជាន់ទី៧), ១២៨, គ្មាន, Tuek L'ak Muoy, Tuol Kouk, Phnom Penh, Cambodia",
           "name":"Tian Xu Internattional Technology Plc",
           "number":"412",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"d5ba58ae-fe3f-47fb-8758-3ee7d2ab0bda",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Building # 398, Preah Monivong Blvd., Sangkat Boeung Keng Kang I, Khan Chamkarmon, Phnom Penh, Cambodia",
           "name":"Visionfund (Cambodia) Ltd",
           "number":"507",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Visionfund",
           "uuid":"9838f3f5-5de4-425b-9e04-4b28d08bd785",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#606, St.271, Sangkat Boeng Tumpun 1, Khan Mean Chey, Phnom Penh",
           "name":"Hattha Kaksekar Limited",
           "number":"503",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Hattha Kasekar",
           "uuid":"cd11bf80-f15a-43a7-96c7-386219eae445",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#721, Preah Monivong Blvd., Sangkat Beung Keng Kang 3, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Wing (Cambodia) Specialized Bank Limited",
           "number":"214",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Wing",
           "uuid":"c303112c-cf33-403d-904c-dd101e51cc4a",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"លេខ១៤៩, ផ្លូវលេខ៤៣២, Phum 5, Boeng Trabaek, Chamkar Mon, Phnom Penh, 12305, Cambodia",
           "name":"Seatel Financial service Plc",
           "number":"405",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"e8d8a3ee-98d0-409c-b978-7c1ad3597da8",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 68, Samdech Pan St.(214), Sangkat Boeung Raing, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Cathay United Cambodia Corporation Bank Limited",
           "number":"119",
           "phone":"",
           "email":"",
           "swift_code":"CSBCKHPP",
           "abbr":"Cathay",
           "uuid":"98c221ef-c161-4c7b-8332-a9281a9ee1c1",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"អគារលេខ ៣១៥ (វិមានកាណាឌីយ៉ា) ជាន់ទី៣. តូបលេខ ០៣-០៥, មហាវិថី ព្រះមុនីវង្ស. កែងផ្លូវព្រះអង្គដួង, Phum 1, Voat Phnum, Doun Penh, Phnom Penh, 12202, Cambodia",
           "name":"Dynamic Payment Plc",
           "number":"416",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"00091412-d939-42c2-85fb-ff6abac3cb74",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#2E-2F, St. 315, Sangkat Boeung Kak 1, Khan Tuol Kok, Phnom Penh, Cambodia.",
           "name":"TBB (Cambodia) Microfinance Institution Plc",
           "number":"364",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"0d1e5ce5-6216-4247-b4d0-e36b4ac04651",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 83, Preah Norodom Blvd, Sangkat phsar Thmey 3, Khan Daun Penh, Phnom penh, Cambodia.",
           "name":"Sathapana Bank Plc.",
           "number":"137",
           "phone":"",
           "email":"",
           "swift_code":"SBPLKHPP",
           "abbr":"Sathapana",
           "uuid":"43d5f25f-b05b-4be9-9109-31e4dc7525aa",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Ground & 1st Floor of B-Ray Tower, Preah Norodom Blvd, Sangkat Tonle Basak, Khan Chamkar Mon, Phnom Penh, Cambodia.",
           "name":"BNKC (Cambodia) Microfinance Institution Plc",
           "number":"309",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"7e3dda99-a08d-4658-9934-7fb8d496a035",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"building # 21 22 23 598 Khan Saensokh, Phnom Penh",
           "name":"Niron Microfinance Plc",
           "number":"341",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"544b0d6c-dfd8-4ed2-8735-c03a4bc61333",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"National Road No5, Phum Prey Korn Sek, Sangkat Ochar, Krong Battambang, Battambang Province, Cambodia.",
           "name":"Baitang Microheranhvatho Plc.",
           "number":"306",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"ef66dc78-0c9e-4d2c-ad5f-3d765e2d7c07",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"អគារលេខ ៨៧, ផ្លូវ ៦៣ (ត្រសក់ផ្អែម), សង្កាត់ បឹងរាំង, ខណ្ឌ ដូនពេញ, រាជធានី ភ្នំពេញ",
           "name":"Bongloy Payment Plc",
           "number":"411",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"8eca002a-8c44-4a6a-987f-05c81b5b4357",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"119B, St. 271, Sankat Phsar Deum Thkov, Chamkarmon, Phnom Penh.",
           "name":"Woori Finance Cambodia Plc",
           "number":"368",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1c3eac04-f6e4-4678-945a-18255f2f538f",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 55, 214 St. Sangkat Boeung Raing, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Kookmin Bank Cambodia Plc.",
           "number":"126",
           "phone":"",
           "email":"",
           "swift_code":"CZNBKHPP",
           "abbr":"",
           "uuid":"f459597c-f612-4251-b32c-62455e5b05e4",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#19, Street 371,Phum Thnout Chrom, 12351",
           "name":"BORIBO Plc.",
           "number":"310",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"ef50f270-ebba-4eb5-a722-b137a001ffdd",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Keystone Building, TrueMoney Company 1,2,4 floor Preah Norodom Boulevard(41), Phnom Penh",
           "name":"True money ( Cambodia )Plc",
           "number":"401",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"TrueMoney",
           "uuid":"7e77d130-d395-4372-ace0-0c9916ce6d5e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 85-88, St. Kim il Sung (289), Sangkat Boeung Kak II,Phnom Penh, Cambodia.",
           "name":"Ly Hour Pay Pro Plc",
           "number":"402",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"LyHour",
           "uuid":"e6bd0d17-d393-4184-a141-a3b9ea44b223",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"AEON MALL Phnom Penh (I), 1st Floor, Samdach Sothearos Blvd, Sangkat Tonle Basak, Khan Chamkar Mon, Phnom Penh, Kingdom of Cambodia",
           "name":"Branch of Mizuho Bank Ltd.",
           "number":"111",
           "phone":"",
           "email":"",
           "swift_code":"MHCBJPJ6",
           "abbr":"",
           "uuid":"1242e2d2-0362-4d2c-9011-d1171df8b539",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#09 A1 A2, St.163, Sangkat ToulSvayPrey I, Khan Chamkamorn, Phnom Penh, Cambodia",
           "name":"T&GO Finance Plc",
           "number":"362",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"51472f14-3bbd-445e-b02b-173fee367bb8",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"21st and 22nd Floor, Building No1, Street 360, Sangkat Boeng Keng Kang Ti Muoy, Khan Chamkar Mon, Phnom Penh",
           "name":"KB Daehan Specialized Bank Plc.",
           "number":"208",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"47aeb829-5078-4a2a-9fce-35b635e20b0d",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Level 2, Vattanac Capital, #66, Preah Monivong Blvd., Sangkat Wat Phnom, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Vattanac Bank",
           "number":"141",
           "phone":"",
           "email":"",
           "swift_code":"VBLCKHPP",
           "abbr":"Vattanac",
           "uuid":"81b0fe37-189d-4f8c-809e-2f83b3166491",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#C1-C2, St. 289 corner of St. 516, Sangkat Boeung Kak 1, Khan Tuol Kouk, Phnom Penh, Cambodia.",
           "name":"Asia Pacific Finance Plc",
           "number":"304",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1cfb6f8e-a6ce-47fc-b325-904947c2c155",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Keystone Building, TrueMoney Company 1,2,4 floor Preah Norodom Boulevard(41), Phnom Penh",
           "name":"True money ( Cambodia )Plc",
           "number":"401",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"TrueMoney",
           "uuid":"382f9501-8189-4561-a4cf-92648011f9c3",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#71BE0 (Borey Piphup Thmey Veng Sreng), St. 02, Sangkat Chaom Chau,Khan Pur Senchey, Phnom Penh, Cambodia.",
           "name":"Bamboo Finance Plc",
           "number":"307",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"cb2640f4-1c7c-4606-a275-126ccdccf420",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#107, Preah Norodom Blvd., Sangkat Boeung Reang, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Saigon-Hanoi Cambodia Bank (SHB) Plc.",
           "number":"136",
           "phone":"",
           "email":"",
           "swift_code":"SHBAKHPP",
           "abbr":"",
           "uuid":"e47f8931-d7d3-48a1-a92f-2fe3d6a882c0",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"",
           "name":"Paragon Payment Service",
           "number":"422",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"b9a92aa2-da7f-465a-aa48-f864fd24264c",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Josep Broz Tito (St. 214), Phnom Penh",
           "name":"Active People's Microfinance Institution Plc",
           "number":"301",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"6d803500-7ca8-4f96-bc5b-bcce4177f884",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. B37, St. 199, 12306 Phnom Penh",
           "name":"Srey Oudom Microfinance Plc",
           "number":"361",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"5b8303d7-2aaa-436d-b73b-d27ac7584339",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#470, Preah Monivong Blvd., Phum 12, Sangkat Tonle Basak, Khan Chamkar Mon, Phnom Penh, Cambodia.",
           "name":"City Microfinance Institution Plc.",
           "number":"317",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"aa39c824-9874-49b9-b2ed-131d5dc99fbb",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"លេខ ៧៩BCE0, ផ្លូវលេខ ១២៨, Phum 2, Monourom, Prampi Makara, Phnom Penh, 12251, Cambodia",
           "name":"Edeel (Cambodia ) Plc",
           "number":"409",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"e8018553-d358-4df0-ba56-178beff2ecf9",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"At Office N° 20A/B, Preah Norodom Blvd Corner of Street 118, Sangkat Phsar Chas, Khan Dong Penh, Phnom Penh.",
           "name":"CIMB Bank Plc.",
           "number":"121",
           "phone":"",
           "email":"",
           "swift_code":"CIBBKHPP",
           "abbr":"CIMB",
           "uuid":"e063f5dc-90a5-4432-bc11-d94b5df4cb63",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#124 , Street Sothea Ros Blvd, Sangkat Tonle Basac , Khan Chamkamorn , Phnom Penh , Camobdia",
           "name":"JET's Cash Box Finance Plc",
           "number":"329",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"b1bf4108-5dc5-4794-833f-ebc1b05dd562",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#68, St. 57, Sangkat Boeng Keng Kang Ti Muoy, Khan Chamkar Mon, Phnom Penh, Cambodia.",
           "name":"Khemarak Microfinance Institution Limited",
           "number":"331",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"37a2c5e9-bb79-4c58-8eaf-2ec47bab840f",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 139,St.274&41, Sangkat Boeung Kengkang1, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Mega International Commercial Bank-Phnom Penh Branch",
           "number":"130",
           "phone":"",
           "email":"",
           "swift_code":"ICBCKHPP",
           "abbr":"",
           "uuid":"9faa89b5-8e3f-4c10-9664-8af2aa539aaf",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#294, Mao Tse Toung Blvd., Sangkat Tomiubteuk, Khan Chamkamorn, Phnom Penh, Cambodia.",
           "name":"Southern Capital Specialized Bank Plc.",
           "number":"213",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"02beb569-7ddc-40f8-a4c5-317b105a8748",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#445, Preah Monivong Blvd., Sangkat Boeung Prolit, Khan 7 Makara, Phnom Penh, Cambodia.",
           "name":"Cambodia Mekong Bank",
           "number":"114",
           "phone":"",
           "email":"",
           "swift_code":"MKGBKHPP",
           "abbr":"",
           "uuid":"113d3549-14a3-41e7-842c-aedfd1dce7a7",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No 36-38B, Northbridge Street, Sangkat Tuek Thla, Khan Sensokh, Phnom Penh",
           "name":"LED Plc",
           "number":"334",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"6e27068a-5b54-47ae-b482-f5c9c49a60ca",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#288, St.1003, Sangkat Phnom Penh Thmey, Khan Sen Sok, Phnom Penh, Cambodia.",
           "name":"Piphup Thmey Microfinance Plc",
           "number":"344",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"2311939b-9295-4d94-9867-f3906a650703",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#175ABCD, Mao Tse Toung Blvd., Phum 5, Sangkat Tuol Svay Prey Ti Muoy, Khan Chamkar Mon, Phnom Penh.",
           "name":"Prince Finance Plc",
           "number":"348",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"c71aa8bc-3466-4e98-9f02-7771ae208e06",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#117, St. 271, Sangkat Stueng Meanchey 1, Khan Meanchey, Phnom Penh, Cambodia.",
           "name":"Bayon Credit Limited Microfinance Institution",
           "number":"308",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"bd51e9a4-bd21-442a-8de0-624e882bac0e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"The iCON Professional Building, #68 (Ground Floor) 216, Preah Norodom Blvd., Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Bank of India Phnom Penh Branch",
           "number":"108",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"cc3a21b2-26b6-43eb-810d-b69aca0c7e41",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#38, Confederation de la Russies, Sangkat Psar De Pou 3, Khan Toul Kork, Phnom Penh, Cambodia.",
           "name":"Khmer Capital Microfinance Institution Plc.",
           "number":"332",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1d0baeff-b3e5-497a-93f6-56399aa931dc",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#73A, St 118, Sangkat Tek Laak I, Khan Toul Kork, Phnom Penh.",
           "name":"YCP Microfinance Limited",
           "number":"369",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"eb656000-4007-4cdc-b9c5-0f5a8ce9f922",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No : 502 C&D, Monivong Blvd, Tonle Bassac, Chamkarmorn, Phnom Penh, Cambodia.",
           "name":"Microfinance Amatak Capital Plc",
           "number":"338",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"2e5f9e0d-cc42-4c87-899e-61a18735efd1",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#398, Preah Monivong Blvd., Sangkat Boeng Keng Kang 1, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Welcome Finance (Cambodia) Plc.",
           "number":"367",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"cecc2e78-e71c-4ccf-b60e-1d923ea02621",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 315, Preah Ang Doung Street, Sangkat Wat Phnom, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Canadia Bank Plc.",
           "number":"118",
           "phone":"",
           "email":"",
           "swift_code":"CADIKHPP",
           "abbr":"Canadia",
           "uuid":"443b596b-4ef3-48e6-942b-1304e38d81ff",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Building No. 439, Preah Monivong Blvd, Sangkat Boeung Prolit, Khan 7 Makara, Phnom Penh, Cambodia.",
           "name":"MB Bank Plc. Phnom Penh Branch, Cambodia",
           "number":"129",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"ce2d7a98-d3c1-4408-84e0-66f13b77cc38",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"7th Floor of Building No. 721, Preah Monivong Blvd., Sangkat Boeng Keng Kang 3, Khan Chamkar Mon, Phnom Penh, Cambodia.",
           "name":"Aeon Specialized Bank (Cambodia) Plc.",
           "number":"201",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Aeon",
           "uuid":"1c33dd06-70cd-4b5f-94ef-492e8b5ef406",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 35BA, Tchecoslovaquie (St. 169), Veal Vong, 7 Makara, 12253, Phnom Penh",
           "name":"Amret Co., Ltd",
           "number":"502",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Amret",
           "uuid":"09093f74-3d97-474d-99f0-cccc9c746357",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#689B, Kampucheakrom Blvd, Sangkat Teuk Laak 1, Khan Koul Kork, Phnom Penh, Kingdom of Cambodia.",
           "name":"DGB Specialized Bank Plc.",
           "number":"206",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"2c5fb6dc-a900-432a-a3dd-9080e6c3c881",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#502G-H, Preah Monivong Blvd., Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Samrithisak Microfinance Limited",
           "number":"358",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"c77dce82-6b24-420e-ad8a-425a6beecbba",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#390-391, St. 271, Phum Tropaing Chhouk, Sangkat Teuk Thlar, Khan Sen Sok, Phnom Penh, Cambodia.",
           "name":"Royal Microfinance Plc",
           "number":"349",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"994113a7-53b5-418a-bbbb-916ccd6e4173",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 61, Preah Monivong Blvd., Sangkat Srah Chork, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"ACLEDA Bank Plc.",
           "number":"101",
           "phone":"",
           "email":"",
           "swift_code":"ACLBKHPP",
           "abbr":"Acleda",
           "uuid":"b46b0159-0c13-4522-8423-5ba4e7482ef4",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No.546 Street 1982 Sangkat Phnom Penh Thmey Khan SenSok Phnom Penh",
           "name":"Delta Microfinance Plc",
           "number":"319",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"21045f99-40d1-46f9-9b0a-6eb1c71118d6",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"16b វិថី រដ្ឋសភា, រាជធានីភ្នំពេញ",
           "name":"WIBS.KH.Payment Service Plc",
           "number":"417",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"30fb2809-6580-41c0-9a92-0a7aedc18e7e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"",
           "name":"LBP Plc",
           "number":"333",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"c71aba3e-0fd2-4b8a-bf39-4d8c2e4f4cf1",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"លេខ B៣&C៣-១, ផ្លូវលេខ ១៦៩, Veal Vong, Prampi Makara, Phnom Penh, Cambodia",
           "name":"Coobill Plc",
           "number":"408",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"9b7f1e45-8920-4369-bd9b-05f0c8502324",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 86-88, St. 41, Preah Norodom Blvd., Sangkat Chaktomuk, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Booyoung Khmer Bank",
           "number":"109",
           "phone":"",
           "email":"",
           "swift_code":"BKBCKHPP",
           "abbr":"",
           "uuid":"70eb1e6e-0be5-4f3a-b783-752a6f961e1c",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 9-11, Samdech Monireth Blvd (217), Sangkat Steung Meanchey, Khan Meanchey, 12352 Phnom Penh",
           "name":"Chokchey Plc",
           "number":"316",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"2b0862f5-55a3-4d07-a651-e8454517285b",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Vanda Tower No 79, Kampuchea Krom Blvd, Sangkat Monourom, Khan Prampir Meakkakra, Phnom Penh",
           "name":"Shinhan Bank (Cambodia) Plc.",
           "number":"138",
           "phone":"",
           "email":"",
           "swift_code":"SHBKKHPP",
           "abbr":"",
           "uuid":"ff3bebce-e9f9-4743-a1e0-d4f2a1da18cb",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No 95, Preah Monivong Blvd, Corner of Street 118, Sangkat Monourom, Khan Prampir Meakkakra, Phnom Penh.",
           "name":"Funan Microfinance Plc.",
           "number":"323",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"8204a7d6-7a79-454a-8928-6f26271e55ec",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Monivong Boulevard, Sangkat Orussey 4, Camodia",
           "name":"ANAKUT Microfinance Institute Plc",
           "number":"302",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Anakut",
           "uuid":"e03e0283-caf0-4531-a895-c1c39579f71e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Prek Thmey Village, Prek Thmey Commune, Koh Thom District, Kandal",
           "name":"Apple Finance Plc",
           "number":"303",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Apple",
           "uuid":"431c7e8c-b78c-45f1-a7dd-589a227116f7",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"ផ្ទះលេខ 54B (ជាន់ផ្ទាល់ដី និងជាន់ទី1), ផ្លូវលេខ 95, -, Boeng Keng kang Bei, Chamkar Mon, Phnom Penh, Cambodia",
           "name":"Aetrino Global Plc",
           "number":"421",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"8bd06ee5-f038-4a43-a39b-efe4d5ab16d9",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 30, St. 432, 12305 Phnom Penh",
           "name":"Cellcard Finance Plc",
           "number":"313",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"7f9eaa0a-dd99-4298-b329-fe26332d0891",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 72, Preah Sihanouk Blvd, Sangkat Tonle Bassak, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"First Investment Specialized Bank",
           "number":"207",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"85ac0824-111e-45aa-aa5d-3cdadd9f5a04",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"",
           "name":"Century Cambo Development Plc",
           "number":"314",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"7d3792d3-d92b-4428-94dd-946957f1ff85",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Room G08-09 GF, Intelligent Office Center (IOC Building) No. 254, Preah Monivong Blvd (93), Phnom Penh",
           "name":"Mother Financial Japan Plc",
           "number":"340",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"405bd917-de7e-4740-bf32-df26c26b58c8",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 586, St. 271, Phum 6, Sangkat Phsar Doem Thkov, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Sahakrunpheap S.T Microfinance Plc",
           "number":"353",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"d2a7bcec-a453-4c14-92a2-b00f4bf1d2c8",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 26, Preah Monivong Blvd., Sangkat Phsar Thmey 2, Khan Daun Penh, Phnom Penh.Cambodia.",
           "name":"Cambodian Commercial Bank Ltd.",
           "number":"116",
           "phone":"",
           "email":"",
           "swift_code":"SICOKHPP",
           "abbr":"",
           "uuid":"325bdef8-e256-421f-bd6b-092febdfbc8b",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#30, Preah Norodom Blvd., Sangkat Phsar Thmey 3, Khan Daun Penh, Phnom Penh.",
           "name":"BRED Bank (Cambodia) Plc.",
           "number":"112",
           "phone":"",
           "email":"",
           "swift_code":"BREDKHP2",
           "abbr":"Bred",
           "uuid":"76c95846-f01c-44e9-a541-76b47a959e25",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"អគារលេខ ១៩៩, ផ្លូវលេខ ២៤៥, គ្មាន, Tuol Svay Prey Pir, Chamkar Mon, Phnom Penh, Cambodia",
           "name":"E-Money Payment Solution Plc",
           "number":"414",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"c7308408-f2b7-4a70-bf86-6bb9af58e70c",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"",
           "name":"Rural Development Bank",
           "number":"212",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"6f04ad7f-3df7-4295-9894-609b52bd9b2b",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#494C, St. 271, Sangkat Tuol Tumpoung Ti Pir, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Prime MF Microfinance Institution Ltd",
           "number":"347",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"a65e3633-be4e-42a8-80f7-dd1da2b12a23",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. C59-61, Cheerfulness (St.), Sangkat Teuk Thla, Khan Sen Sok, 12102 Phnom Penh",
           "name":"SAMIC Microfinance Institution Plc",
           "number":"356",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"d2a6682c-d825-48aa-8ea2-26b266de5d2b",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 66, Preah Norodom Blvd., Sangkat Chey Chumneas, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"First Commercial Bank Phnom Penh Branch",
           "number":"122",
           "phone":"",
           "email":"",
           "swift_code":"FCBKKHPP",
           "abbr":"",
           "uuid":"785205b0-4c02-4fe0-b847-076a3ea69e25",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#20E0, St. 178, Sangkat Phsar Thmey III, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"PG DEVELOPMENT Plc.",
           "number":"343",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"16d620c0-1f0f-4e4e-b56c-e0b1eecdbd85",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#228JA, St. 598, Toul Kork Village, Sangkat Tuol Sangkae, Khan Russey Kaev, Phnom Penh, Cambodia.",
           "name":"Sambat Finance Plc",
           "number":"355",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"4b957fb0-0705-454e-8847-e0379b9a39ed",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Nº B2-033, Street Koh Pich, Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh 12301, Cambodia.",
           "name":"GBCI Ventures Plc",
           "number":"424",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"10ec0289-5854-40d2-8bed-579f13787cb9",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Exchange Square (Ground Floor), No. 19 and 20, Street 106, Phum Pir, Sangkat Voat Phnom, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"ICBC Limited Phnom Penh Branch",
           "number":"125",
           "phone":"",
           "email":"",
           "swift_code":"ICBKKHPP",
           "abbr":"",
           "uuid":"8527e581-b2c6-42c9-9191-f7db6a282a6e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 55, 214 St. Sangkat Boeung Raing, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Kookmin Bank Cambodia Plc.",
           "number":"126",
           "phone":"",
           "email":"",
           "swift_code":"CZNBKHPP",
           "abbr":"",
           "uuid":"82b3f667-a92e-435b-a58e-30bbd87a4bc7",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 101A, Street 289, Sangkat Boeung Kak 1, Khan Toul Kork, Phnom Penh.",
           "name":"Camma Microfinance Limited",
           "number":"312",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"f81185c8-1791-486c-b8ae-51f6a5d97964",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#370, Preah Monivong Blvd, Sangkat Boeung Keng Kang 1, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Bank for Investment and Development of Cambodia Plc.",
           "number":"106",
           "phone":"",
           "email":"",
           "swift_code":"IDBCKHPP",
           "abbr":"",
           "uuid":"77404f56-b1e4-4f03-82da-a650ceee3b24",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Diamond TwinTower, the corner of Street Sopheak Mongkul and, Koh Pich St, 12351 Phnom Penh, 12301",
           "name":"Atom Capital Ltd.",
           "number":"305",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"0a90fa0e-9156-4cba-8e9c-4e7de9fcdc5c",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Floor 4, S&C Office Space Building, Street 1984A, Sen Sok, Phnom Penh, Kampuchia (Cambodia)",
           "name":"Kess Innovation Plc",
           "number":"423",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"9cb28177-c479-45b2-b715-f81c7e0d68ff",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#20, Street 217, Sangkat Veal Vong, Khan 7 Makara, Phnom Penh, Cambodia",
           "name":"Pi Pay Plc",
           "number":"403",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"PiPay",
           "uuid":"05de8284-9b9b-4704-8039-62b3f87ff6f7",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#C01, St. R11 corner of St. 70, Phum 1, Sangkat Sras Chork, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Chief (Cambodia) Bank Plc.",
           "number":"120",
           "phone":"",
           "email":"",
           "swift_code":"CHBCKHPP",
           "abbr":"",
           "uuid":"f41eae02-b214-41ff-b057-8754943178b1",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 35BA, Tchecoslovaquie (St. 169), Veal Vong, 7 Makara, 12253, Phnom Penh",
           "name":"Amret Co., Ltd",
           "number":"502",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Amret",
           "uuid":"e56a141b-f588-4da0-95a1-3ff38ee09ab9",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#425, St. 271, Sangkat Tuol Tumpong 2 Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Chamroeun Microfinance Plc",
           "number":"315",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"b9db5484-d7fa-485c-9f8a-c1c1399aea26",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"666B Yothapol Khemarak Phoumin Blvd (271), Phnom Penh",
           "name":"LOLC (Cambodia) PLc",
           "number":"505",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"LOLC",
           "uuid":"c682b280-8009-47ec-bea3-dc013f70622b",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 20, 217 St., Sangkat Veal Vong, Khan 7Makara, Phnom Penh, Cambodia.",
           "name":"ANCO Specialized Bank",
           "number":"202",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"e015fa54-4b28-487c-b016-8f702cad50d6",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#72, Norodom Blvd, Sangkat Chey Chumneas, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"PHSME Specialized Bank Ltd.",
           "number":"211",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"4bd4170e-e140-40ff-a39d-310126ff7718",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 189-191, Kampuchea Krom (St. 128), 12252, Phnom Penh, Cambodia",
           "name":"Entean Akpevath Pracheachun Limited",
           "number":"320",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"24d112ce-2c6b-408d-a28e-ac0348ce45d6",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#46, St. 41, Sangkat Chey Chamnes,Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Oxley Worldbridge Specialized Bank Plc.",
           "number":"210",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1c51680b-74b8-4a96-84a7-8b536bc3c5f4",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 779A, Kampuchea Krom Blvd (128), Sangkat Teuk Laak I, Khan Toul Kork, Phnom Penh",
           "name":"Intean Poalroath Rongroeurng Ltd.",
           "number":"328",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"2e2f0e3f-094c-4256-abb1-362f4d2931d6",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#175CD, Mao Tse Toung Blvd., Phum 5, Sangkat Tuol Svay Prey Ti Muoy, Khan Chamkar Mon, Phnom Penh",
           "name":"Prince Bank Plc.",
           "number":"133",
           "phone":"",
           "email":"",
           "swift_code":"PINCKHPP",
           "abbr":"Prince",
           "uuid":"aebe5a15-a9a9-4157-a031-9838accdddee",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#33 C-D, Tchecoslovaquie Blvd., Sangkat Veal Vong, Khan 7 Makara, Phnom Penh, Cambodia.",
           "name":"Foreign Trade Bank of Cambodia",
           "number":"123",
           "phone":"",
           "email":"",
           "swift_code":"FTCCKHPP",
           "abbr":"FTB",
           "uuid":"89a70914-e4be-414f-97a1-07a5f7eaa857",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"អគារលេខi៤៦ D.I.Riviera, វិថីRoad Park កោះពេជ្រ, Phum 14, Tonle Basak, Chamkar Mon, Phnom Penh, Cambodia",
           "name":"Dara Sakor Pay Plc",
           "number":"419",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"9693ece6-5dd5-4073-9c71-41b1d49178b6",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 149, 215 Road, Sangkat Phsar Depo1, Khan Toulkork,Phnom Penh, Cambodia.",
           "name":"Krung Thai Bank Phnom Penh Branch",
           "number":"127",
           "phone":"",
           "email":"",
           "swift_code":"KRTHKHPP",
           "abbr":"",
           "uuid":"e2852f33-f3b7-4b6e-9fe4-4eb3e01be51c",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#795, Preah Monivong Blvd, Sangkat Beung Trabek, Khan Chamkamorn, Phnom Penh.",
           "name":"Futaba Microfinace Plc.",
           "number":"324",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"7d7ddb24-87ff-43dc-97fa-e6b305a3f1d9",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"120101, 228 Preah Norodom Blvd (41), Phnom Penh",
           "name":"Sabay Credit Commercial Plc",
           "number":"350",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"dde86fcb-bb1f-48c0-9ae2-34ce9ba76631",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"House B2-035-036 St Koh Pich, Sangkat Tonle Bassac, Phnom Penh",
           "name":"Asia Cash Express Plc",
           "number":"404",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1814f8f9-7403-4267-9fc3-27814b8fef23",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":" #132, Corner of Street 294 and Preah Norodom Blvd., Sangkat Tonle Bassac, Khan Chankarmon, Phnom Penh, Cambodia.",
           "name":"Asia-Pacific Development Specialized Bank Plc.",
           "number":"204",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"179e6306-35fd-424a-97c5-2d09c928d580",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#345, Mao Tse Toung Blvd., Sangkat Phsar Depou Ti Muoy, Khan Tuol Kouk, Phnom Penh, Cambodia.",
           "name":"Khmer Specialized Bank Plc.",
           "number":"209",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1f906ada-f3c8-468e-84c6-312476e10c5a",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 61, St.130 , Sangkat Phsar Chas, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Union Commercial Bank Plc.",
           "number":"140",
           "phone":"",
           "email":"",
           "swift_code":"UCBPKHPP",
           "abbr":"",
           "uuid":"9f5eecef-3884-458e-a750-670b86922d4c",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#119, St. 592, Phum 14, Sangkat Beong Kok II, Khan Tuol Kouk, Phnom Penh, Cambodia.",
           "name":"Ponleu Chaktomuk Microfinance Institution Plc.",
           "number":"345",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"7c5186af-86fc-4445-a518-ad9b6211a17a",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#684, St. 7 Makara, Phum Chhunlung, Sangkat Salakamreok, Siem Reap Province, Cambodia.",
           "name":"Golden Cash Plc",
           "number":"325",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"03e49d4b-2271-41eb-97ed-5bc0a54eafdc",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 21 AB, Street 271, Sangkat Phsar Doeum Thkov, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Maxima Microfinance Plc.",
           "number":"336",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"17260d35-6ce6-4946-bf35-3efea020e2ee",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"no. 1465, National, Sangkat Chak Angre Krom, Phnom Pemh, St Duong Ngeap Street II, Phnom Penh",
           "name":"Farmer Finance Ltd",
           "number":"321",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"a5459942-bfbe-498c-9fd3-eabb0738bc72",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#45, Preah Sihanuk Blvd., Sangkat Chaktomuk, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Branch of Kasikorn Bank Public Company Limited (Phnom Penh)",
           "number":"110",
           "phone":"",
           "email":"",
           "swift_code":"KASIKHPP",
           "abbr":"",
           "uuid":"6eb9fc0b-548f-4cec-ba74-58b006caf02d",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#464A, Monivong Blvd, Sangkat Tonle Bassac, Phnom Penh",
           "name":"SmartLuy Plc",
           "number":"418",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"SmartLuy",
           "uuid":"41015db0-9ce3-46f6-95a7-a60c07a0d8b2",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 206E1E0,Str. 155 Sangkat Toul Tompong, National Hwy 1, Phnom Penh",
           "name":"Sahaka Plc",
           "number":"352",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"e8785e51-87a0-4b3e-9b51-96ad44b2890a",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No.14, Acacia St, Ground Floor #252 Rose Garden, Sangkat Tonle Bassac Khan Chamkamorn Phnom Penh, 12301",
           "name":"Dragonfly FinTech (Cambodia ) Plc",
           "number":"415",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"4fb9eb01-3a02-4649-9ca0-4b151c8b9516",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#27 DEF, Preah Monivong Blvd., Sangkat Srah Chork, Khan Daun Penh, Phnom Penh, Cambodia.",
           "name":"Phillip Bank Plc.",
           "number":"131",
           "phone":"",
           "email":"",
           "swift_code":"HDSBKHPP",
           "abbr":"PhillpBank",
           "uuid":"73cdd434-1100-421d-bb90-1f0f42c33095",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 148, Preah Sihanouk Blvd., Sangkat Boeung Kengkang 1, Khan Chamkarmorn, Phnom Penh, Cambodia.",
           "name":"Advanced Bank of Asia Limited",
           "number":"102",
           "phone":"",
           "email":"",
           "swift_code":"ABAAKHPP",
           "abbr":"ABA",
           "uuid":"d85d9af9-bb86-4d74-b047-2161e5c074f0",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Building № 212, Street 271, Sangkat Tuol Tumpung 2, Khan Chamkarmon Phnom Penh, 12311",
           "name":"Prasac Microfinance Institution Limited",
           "number":"506",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Prasac",
           "uuid":"a7db0a6f-ab7b-4410-85ff-9effd8f4bfb8",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#25, St. 1015, Phum Bayab, Sangkat Phnom Penh Thmey, Khan Sen Sokh, Phnom Penh, Cambodia.",
           "name":"Sachak Microfinance Plc",
           "number":"351",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"f880a3fe-4187-4f5e-a760-d454ddbd07f1",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. R02, Ruby (St.), 12306 Phnom Penh",
           "name":"Collective Win Cambodia Plc",
           "number":"318",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"f7c268cc-80ff-4e31-abc9-bdb5aa5bd188",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"# 432, Preah Monivong Blvd., Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh",
           "name":"Sonatra Microfinance Institution Plc",
           "number":"360",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1865f578-dfb2-488e-a2f0-76aedc88ec3d",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#445, St. 4, Phum Souphi, Sangkat Kompong Svay, Krong Serei Saophoan, Banteay Meanchey Province, Cambodia.",
           "name":"Trop Khnhom Microfinance Plc.",
           "number":"365",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"5e7b7f48-00e5-40ea-a127-f0a8cdc4e823",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#62 Preah Norodom Blvd (41) , Phnom Penh , Cambodia",
           "name":"Huione Pay Plc",
           "number":"413",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"9fc037d5-9990-4638-b90f-af303c40e290",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#24, Yothapol Khemarak Phoumin Blvd (271), Sangkat Ou Baek K'am, Khan Sen Sok, Phnom Penh, Kingdom of Cambodia.",
           "name":"Mohanokor Microfinance Plc",
           "number":"339",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"5a8128f4-aeec-4fcb-a60a-8a9b3157c2d0",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#81, Preah Norodom Blvd, Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Camko Specialized Bank Plc.",
           "number":"205",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"1dd33b5d-822f-4d58-ae9b-8368c1072e4e",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#75C.036, St. Preah Sihanouk, Sangkat Veal Vong, Khan 7 Makara, Phnom Penh, Cambodia.",
           "name":"Cambodia Asia Bank Ltd.",
           "number":"113",
           "phone":"",
           "email":"",
           "swift_code":"CABCKHPP",
           "abbr":"",
           "uuid":"0ae48c12-6a84-43d8-8589-80b841b3687c",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"No. 84, Mao Tse Toung Blvd (245), 12305 Phnom Penh",
           "name":"Cambodian Labor Care Plc",
           "number":"311",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"d3a735d0-388a-4f20-86aa-69638161bbfc",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#S04, National Road 5, Phum Prekmohatep, Sangkat Svaypor, Krong Battambang, Battambang Province, Cambodia.",
           "name":"Idemitsu Saison Microfinance (Cambodia) Plc",
           "number":"327",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"a40e009e-0bf1-4b0c-a1be-d250a5e0b488",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#217, Preah Norodom Blvd., Sangkat Tonle Bassac, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Phnom Penh Commercial Bank",
           "number":"132",
           "phone":"",
           "email":"",
           "swift_code":"PPCBKHPP",
           "abbr":"PPCB",
           "uuid":"0e6b838c-4e45-4a58-99fc-75bcda35ef33",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#147, Preah Monireth Blvd., Sangkat Boeung Salang, Khan Tuol Kork, Phnom Penh, Cambodia.",
           "name":"Oro Financecorp Plc.",
           "number":"342",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"d1cf2504-273e-4eb4-b7d3-ea07fe73a0e3",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#345, Mao Tse Toung Blvd., Sangkat Phsar Depou Ti Muoy, Khan Tuol Kouk, Phnom Penh, Cambodia.",
           "name":"Khmer Specialized Bank Plc.",
           "number":"209",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"61110daf-9611-4369-b1de-cfce3656d1a7",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Olympia S2 13-14 St. 217 Phnom Penh, 12253",
           "name":"Ipay88 ( Cambodia ) Plc",
           "number":"410",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"ca943a77-b801-40a2-9f7b-9f74dda09594",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#119, St. 592, Phum 14, Sangkat Beong Kok II, Khan Tuol Kouk, Phnom Penh, Cambodia.",
           "name":"Ponleu Chaktomuk Microfinance Institution Plc.",
           "number":"345",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"14f8a3ec-0cea-45fd-b118-c56a3a26b51f",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#1-2SF, St. 516, Phum 13, Sangkat Boeung Kak 1, Khan Tuol Kork, Phnom Penh, Cambodia.",
           "name":"Seilanithih Limited",
           "number":"359",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"37b543ce-c035-422f-8c27-3489678f2170",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#A73-75, St. 271, Group 34, Village 4, Sangkat Tomoub Tek, Khan Chamkar Mon, Phnom Penh, Cambodia.",
           "name":"Vithey Microfinance Plc.",
           "number":"366",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"",
           "uuid":"eedced68-773c-471b-81ad-ec653503a68d",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#9-13, St. 07, Chakto Mukh Sangkat, Doun Penh Khan, Phnom Penh Capital, Cambodia",
           "name":"Vietnam Bank for Agriculture & Rural Development Cambodia Branch",
           "number":"142",
           "phone":"",
           "email":"",
           "swift_code":"VBAAKHPP",
           "abbr":"",
           "uuid":"a2d2cde8-bb53-42c0-857a-a3b43cb76f03",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"#171, Norodom Blvd. (Corner Street 322), Sangkat Boeung Keng Kang 1, Khan Chamkarmon, Phnom Penh, Cambodia.",
           "name":"Taiwan Cooperative Bank Phnom Penh Branch",
           "number":"139",
           "phone":"",
           "email":"",
           "swift_code":"TACBKHPP",
           "abbr":"",
           "uuid":"e2a859ac-565e-4bb1-8788-301fb3e7a0fa",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        },
        {
           "address":"Building 71,Sang Kat Toul Svay Prey I Khan Beoung Keng Kang, St 163, Phnom Penh",
           "name":"Kredit Microfinance Institution Plc",
           "number":"504",
           "phone":"",
           "email":"",
           "swift_code":"",
           "abbr":"Kredit",
           "uuid":"0eb5d947-7caa-4e15-b93e-907a8f80c689",
           "is_deleted":0,
           "is_in_used":0,
           "id":null,
           "version":0,
           "txTime":null,
           "txId":null
        }
    ],
    employeeType: [
        {
            id: '98c3ce4a-3b85-11eb-adc1-0242ac120002',
            name: 'Full-time'
        },
        {
            id: '98c3d2be-3b85-11eb-adc1-0242ac120002',
            name: 'Part-time'
        },
        {
            id: '98c3d3cc-3b85-11eb-adc1-0242ac120002',
            name: 'Casual'
        },
        {
            id: '98c3d49e-3b85-11eb-adc1-0242ac120002',
            name: 'Fixed term'
        },
        {
            id: 'aa7bdea6-3c26-11eb-adc1-0242ac120002',
            name: 'Shift worker'
        },
        {
            id: 'aa7be0ae-3c26-11eb-adc1-0242ac120002',
            name: 'Daily hire and weekly hire'
        },
        {
            id: 'aa7be1a8-3c26-11eb-adc1-0242ac120002',
            name: 'Probation'
        },
        {
            id: 'aa7be270-3c26-11eb-adc1-0242ac120002',
            name: 'Apprentices and trainees'
        }
    ],
    institutes: 'https://myconnect.s3-ap-southeast-1.amazonaws.com/instituteList.json',
    connectedBanks: 'https://gist.githubusercontent.com/nimol-hooligan/611e93ca6b7fd87e4a8cd18252be77bf/raw/c34af0e83427bb00d3785472c4cbdffa5f97c351/connected_bank.json'
}
